import React from "react";

function Shop() {
  return (
    <div>
      <h1>This is cart</h1>
    </div>
  );
}

export default Shop;
